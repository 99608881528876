import React, {useCallback, useContext, useEffect, useState} from "react";
import {createTheme, ThemeProvider as MUIThemeProvider} from "@mui/material";
import {blue, pink} from "@mui/material/colors";
import {useAuth} from "../auth/auth";

export interface Properties {
    children: React.ReactNode
}

export const THEME_DARK = "dark";
export const THEME_LIGHT = "light";

const STORAGE_EVENT = "storage";
const STORAGE_KEY_THEME_TYPE = "@theme/theme_type";

export type ThemeType = (typeof THEME_DARK | typeof THEME_LIGHT);

export type ProviderType = {
    theme_type: ThemeType
    toggleTheme(): void
};
export type ProviderDefaultType = undefined;
export type ContextType = ProviderType | ProviderDefaultType;

export const Context = React.createContext<ContextType>(undefined);

const STORAGE_THEME_TYPE = "@theme/theme-type";

export default function ThemeProvider(props: Properties) {
	const auth_context = useAuth();
	if (!auth_context) throw new Error("Context must be called inside provider");


	const [theme_type, setThemeType] = useState<ThemeType>(THEME_LIGHT);

	useEffect(() => {
		const data = localStorage.getItem(STORAGE_THEME_TYPE);
		if (data) setThemeType(data as ThemeType);
	}, []);

	const onStorageEvent = useCallback((event: StorageEvent) => {
		const value = event.newValue;
		if (event.key == STORAGE_THEME_TYPE && value != null) {
			setThemeType(value as ThemeType);
		}
	}, []);

	useEffect(() => {
		window.addEventListener(STORAGE_EVENT, onStorageEvent);
		return () => {
			window.removeEventListener(STORAGE_EVENT, onStorageEvent);
		};
	}, []);

	const toggleTheme = () => {
		const value = theme_type == THEME_DARK ? THEME_LIGHT : THEME_DARK;
		localStorage.setItem(STORAGE_THEME_TYPE, value);
		return setThemeType(value);
	};

	const theme = createTheme({
		palette: {
			mode: theme_type,
			primary: {
				main: theme_type == THEME_DARK ? blue[600] : blue[300]
			},
			secondary: {
				main: theme_type == THEME_DARK ? pink[800] : pink[400]
			}
		}
	});

	return (
		<Context.Provider value={{
			theme_type, toggleTheme
		}}>
			<MUIThemeProvider theme={theme}>
				{props.children}
			</MUIThemeProvider>
		</Context.Provider>
	);
}

export function useTheme() {
	return useContext(Context);
}