import React, {SyntheticEvent, useCallback, useState} from "react";

import {Alert, AlertColor, Snackbar, SnackbarCloseReason, Typography} from "@mui/material";

export interface MessageProperties {
    message?: string
    severity?: AlertColor
}

export interface Properties extends MessageProperties {
    auto_hide_duration?: number
}

export type NotifyType = (props: MessageProperties) => void;

const AUTO_HIDE_DURATION = 5000;

export default function useToast(): [(props: Properties) => JSX.Element, NotifyType] {
	const [is_open, setIsOpen] = useState<boolean>(false);
	const [message, setMessage] = useState<string>();
	const [severity, setSeverity] = useState<AlertColor>();

	const notify: NotifyType = (props: MessageProperties) => {
		setIsOpen(true);

		if (props.message != null && props.message.length) setMessage(props.message);
		if (props.severity != null) setSeverity(props.severity);
	};

	const handleAlertClose = (event: Event | SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
		if (reason === "clickaway") return;
		setIsOpen(false);
	};

	const component = useCallback((props: Properties) => (
		<React.Fragment>
			<Snackbar open={is_open} onClose={handleAlertClose}
				autoHideDuration={props.auto_hide_duration || AUTO_HIDE_DURATION}
				anchorOrigin={{vertical: "bottom", horizontal: "right"}}
			>
				<Alert onClose={handleAlertClose} severity={severity}>
					<Typography>{message != "" ? message : "..."}</Typography>
				</Alert>
			</Snackbar>
		</React.Fragment>
	), [is_open, message, severity]);

	return [component, notify];
}