import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Collapse, IconButton, Table, TableBody, TableContainer, TableHead, TableRow, List, ListItem, Typography} from "@mui/material";
import {
	KeyboardArrowUp as KeyboardArrowUpIcon, KeyboardArrowDown as KeyboardArrowDownIcon
} from "@mui/icons-material";

import {OrderCollection} from "../../../../services/actions/actions";

import {toCurrency} from "../../../../utils/misc";

import {StyledTableRow, StyledTableCell} from "./styles";

export interface Properties {
    items: OrderCollection.OrderProductItem[]
}

export default function ItemTable(props: Properties) {
	const {t, i18n} = useTranslation();

	return (
		<Box sx={{display: "flex", flexWrap: "wrap", rowGap: "12px", padding: "8px 12px"}}>
			{props.items.map((item, index) => (
				<Box sx={{flexBasis: "50%", flexGrow: 1}}>
					<Typography>{`${index + 1}. ${item.title} - ${toCurrency(item.payment_value)}`}</Typography>
					{item.picks.length && (<Box sx={{marginLeft: "24px"}}>
						{item.picks.map((pick) => (
							<Typography>{`• ${pick.quantity}x - ${pick.name} - ${toCurrency(pick.payment_value)}`}</Typography>
						))}
					</Box>)}
				</Box>
			))}
		</Box>
	);
}