import React, {useCallback, useContext, useEffect, useState} from "react";
import {CircularProgress, Container, Grid} from "@mui/material";

import vow from "../../../../pkg/utils/vow";

import {AuthCollection, UserCollection} from "../../../../ctx/services/actions/actions";

import useIsMounted from "../../components/hooks/mounted";

export interface Settings {
    domain: string
}

export interface Properties {
    setts: Settings
    children: React.ReactNode
}

interface LoginOptions extends AuthCollection.LoginOptions {

}

export type ProviderType = {
    user?: UserCollection.UserResult
    login(options: LoginOptions): Promise<void>
    logout(): Promise<void>
	isLogged(): boolean
};
export type ProviderDefaultType = undefined;
export type ContextType = ProviderType | ProviderDefaultType;

export const Context = React.createContext<ContextType>(undefined);

const STORAGE_AUTH_USER = "@auth/user";

export default function AuthProvider(props: Properties) {
	const [user, setUser] = useState<UserCollection.UserResult>();
	const is_mounted = useIsMounted();

	useEffect(() => {
		const data = localStorage.getItem(STORAGE_AUTH_USER);
		if (data) setUser(JSON.parse(data));
	}, []);

	const getUser = useCallback(async () => {
		const result = await vow.handle(UserCollection.get());
		if (result instanceof Error) {
			const error = new Error(`Can't get user: '${result.message}'`);
			error.name = result.name;
			throw error;
		}
		return result;
	}, []);

	const login = useCallback(async (options: LoginOptions) => {
		localStorage.clear();

		const result = await vow.handle(AuthCollection.login(options));
		if (result instanceof Error) {
			const error = new Error(`Can't login: '${result.message}'`);
			error.name = result.name;
			throw error;
		}

		const result_user = await vow.handle(getUser());
		if (result_user instanceof Error) throw result_user;

		setUser(result_user);
		localStorage.setItem(STORAGE_AUTH_USER, JSON.stringify(result_user));
	}, []);

	const logout = useCallback(async () => {
		const result = await vow.handle(AuthCollection.logout());
		if (result instanceof Error) {
			throw new Error(`Can't logout: '${result.message}'`);
		}

		setUser(undefined);

		localStorage.setItem(STORAGE_AUTH_USER, "");
		localStorage.clear();
	}, []);

	const isLogged = () => !!user;

	return (
		<Context.Provider value={{user, login, logout, isLogged}}>
			{user && !is_mounted && (
				<Container component="main" maxWidth="sm">
					<Grid container direction="column" alignItems="center">
						<CircularProgress/>
					</Grid>
				</Container>)
			||
				props.children
			}
		</Context.Provider>
	);
}

export function useAuth() {
	return useContext(Context);
}