/* backend-api client-manager errors */
export const ErrRefTokenInvalid = "ErrRefTokenInvalid";
export const ErrAccTokenInvalid = "ErrAccTokenInvalid";
export const ErrDuplicatedEntry = "ErrDuplicatedEntry";
export const ErrMaxItems = "ErrMaxItems";

export const ErrCantReadFile = "ErrCantReadFile";
export const ErrMaxImgSize = "ErrMaxImgSize";
export const ErrDispMaxTimeRanges = "ErrDispMaxTimeRanges";
export const ErrDispInvalidTimeRange = "ErrDispInvalidTimeRange";
export const ErrDispTimeRangeInUse = "ErrDispTimeRangeInUse";
export const ErrMaxItemPicks = "ErrMaxItemPicks";
export const ErrItemInvalid = "ErrItemInvalid";
export const ErrInvalidCategory = "ErrInvalidCategory";

export const ErrParentIsBusy = "ErrParentIsBusy";

export function identifyError(payload: any) {
	const error = new Error(payload.message);
	if (payload.error) error.name = payload.error;
	return error;
}