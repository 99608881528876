import React from "react";
import {Link, TableHead, TableSortLabel} from "@mui/material";
import {useTranslation} from "react-i18next";

import {StyledTableRow, StyledTableCell} from "./styles";
import {SortKeyTypes, SortOrderTypes} from "./order-table";

export interface Properties {
    sort_key: SortKeyTypes
    sort_order: SortOrderTypes
    onSelectSort(sort_key: SortKeyTypes, sort_order: SortOrderTypes): void
}

export default function SortedTableHead(props: Properties) {
	const {t, i18n} = useTranslation();

	const handleOnSelect = (sort_key: SortKeyTypes, sort_order: SortOrderTypes) => {
		props.onSelectSort(sort_key, sort_order);
	};

	const direction = props.sort_order == "asc" ? "desc" : "asc";

	return (
		<TableHead>
			<StyledTableRow>
				<SortedTableHeadCell active={props.sort_key == "id"} order={props.sort_order}
					onSelect={() => handleOnSelect("id", direction)}
				>
					<Link underline="always" color="primary">{t("order-table-id")}</Link>
				</SortedTableHeadCell>
				<StyledTableCell>{t("order-table-customer")}</StyledTableCell>
				<StyledTableCell>{t("order-table-address")}</StyledTableCell>
				<SortedTableHeadCell active={props.sort_key == "status"} order={props.sort_order}
					onSelect={() => handleOnSelect("status", direction)}
				>
					<Link underline="always" color="primary">{t("order-table-status")}</Link>
				</SortedTableHeadCell>
				<SortedTableHeadCell active={props.sort_key == "payment"} order={props.sort_order}
					onSelect={() => handleOnSelect("payment", direction)}
				>
					<Link underline="always" color="primary">{t("order-table-payment-value")}</Link>
				</SortedTableHeadCell>
				<SortedTableHeadCell active={props.sort_key == "created_at"} order={props.sort_order}
					onSelect={() => handleOnSelect("created_at", direction)}
				>
					<Link underline="always" color="primary">{t("order-table-created-at")}</Link>
				</SortedTableHeadCell>
			</StyledTableRow>
		</TableHead>
	);
}

interface SortedTableHeadCellProperties {
    active: boolean
    order: SortOrderTypes
    children: JSX.Element
    onSelect: () => void
}

function SortedTableHeadCell(props: SortedTableHeadCellProperties) {
	return (
		<StyledTableCell onClick={props.onSelect}>
			<TableSortLabel active={props.active} direction={props.active ? props.order : "asc"}>
				{props.children}
			</TableSortLabel>
		</StyledTableCell>
	);
}