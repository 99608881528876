import React, {useRef, useState} from "react";
import {
	Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton,
	InputLabel, Select, Typography, TextField, TextFieldProps
} from "@mui/material";
import {Close as CloseIcon} from "@mui/icons-material";
import {Print as PrintIcon} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {useReactToPrint} from "react-to-print";

import vow from "../../../../../pkg/utils/vow";
import logger from "../../../../../pkg/utils/logger";

import {useAuth} from "../../../contexts/auth/auth";
import {useStore} from "../../../contexts/store/store";
import {OrderCollection} from "../../../../services/actions/actions";

import {toCurrency} from "../../../../utils/misc";

import {ProductTable} from "./product-table";
import PrintableOrder from "./printable-order";
import {STORE_GUEST_PERMISSIONS} from "../../../../services/actions/store/structures";

export interface Properties {
    order: OrderCollection.OrderResult
    onClose(): void
    onChange(order: OrderCollection.OrderResult): void
    onChangeError(error: Error): void
}

const formatAddress = (address: OrderCollection.Address) => {
	return `${address.address} - ${address.city} - ${address.state} - ${address.country} - ${address.postal_code}`;
};

export default function OrderDialog(props: Properties) {
	const {t, i18n} = useTranslation();

	const [selected_status, setSelectedStatus] = useState<OrderCollection.OrderStatus>();

	const printableComponentRef = useRef<HTMLDivElement>(null);
	const handlePrint = useReactToPrint({
		content: () => printableComponentRef.current
	});

	const item = props.order;

	const auth_context = useAuth();
	if (!auth_context) throw new Error("Context must be called inside provider");

	const store_context = useStore();
	if (!store_context) throw new Error("Context must be called inside provider");

	const selected_store = store_context.getSelectedStore();

	const current_status = selected_status ? selected_status : props.order.status;

	const updateOrderStatus = async () => {
		const result = await vow.handle(OrderCollection.updateStatus({
			order_id: props.order.id, store_id: props.order.store_id, status: current_status
		}));
		if (result instanceof Error) {
			throw new Error(`Can't update order status: ${result.message}`);
		}
		if (!result.status) {
			throw new Error("Can't update order status");
		}
		return true;
	};

	const handleCloseDialog = () => {
		props.onClose();
	};

	const handleUpdateStatus = async () => {
		const result = await vow.handle(updateOrderStatus());
		if (result instanceof Error) {
			props.onChangeError(result);
		} else {
			props.onChange({...props.order, status: current_status});
		}
		handleCloseDialog();
	};

	return (
		<Dialog open={!!item} onClose={handleCloseDialog} maxWidth="sm">
			<PrintableOrder ref={printableComponentRef} order={item}/>
			<DialogTitle sx={(theme) => ({margin: 0, padding: theme.spacing(2)})}>
				{t("order-modal-title", {id: item.id})}
				<IconButton sx={(theme) => ({
					position: "absolute", right: theme.spacing(1), top: theme.spacing(1)
				})} onClick={handleCloseDialog}>
					<CloseIcon/>
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<Grid container>
					<Grid container sx={{flexDirection: "row", justifyContent: "space-between"}}>
						<Grid sx={(theme) => ({marginRight: theme.spacing(1)})} item>
							<Typography variant="body1">
								{t("order-table-customer") + ": " + item.info.customer.name}
							</Typography>
							<Autocomplete
								sx={(theme) => ({maxWidth: "250px", marginTop: theme.spacing(2)})}
								value={selected_status ? selected_status : props.order.status}
								options={["approval", "progress", "done"]}
								getOptionLabel={(option) => t(`order-status-${option}`)}
								renderOption={(props, option) => (
									<li {...props}>{t(`order-status-${option}`)}</li>
								)}
								disabled={selected_store?.is_external && !selected_store.guest_permissions?.find(v => v === STORE_GUEST_PERMISSIONS.LET_CHANGE_ORDER_STATUS)}
								onChange={(e, v) => setSelectedStatus(v as OrderCollection.OrderStatus)}
								renderInput={(params) => (
									<TextField {...(params as TextFieldProps)} label={t("order-table-status")}/>
								)}
							/>
						</Grid>
						<Grid item>
							<Typography variant="body2">
								{new Date(item.created_at).toLocaleString(i18n.language)}
							</Typography>
						</Grid>
					</Grid>
					<Grid sx={(theme) => ({marginTop: theme.spacing(2)})} container direction="row">
						<Grid item>
							<Typography variant="body1">
								{t("order-table-method") + ": " + t(`order-table-method-type-${item.method}`)}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body1">
								{t("order-table-address") + ": " + formatAddress(item.info.customer.address)}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<ProductTable products={item.products}/>
				<Box sx={{display: "flex", marginTop: (theme) => theme.spacing(2), justifyContent: "space-between"}}>
					<Typography variant="body1">
						{t("order-table-payment-value") + ": " + toCurrency(item.payment.value)}
					</Typography>
					<Typography variant="body1">
						{t("order-table-payment-method") + ": " + t(`order-table-payment-method-type-${item.payment.method}`)}
					</Typography>
					{!!item.payment.change_for && (
						<Typography variant="body1">
							{t("order-table-payment-change-for") + ": " + toCurrency(item.payment.change_for)}
						</Typography>
					)}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleUpdateStatus} color="primary" variant="contained" autoFocus>
					{t("word-update")}
				</Button>
				<Button onClick={handlePrint} color="primary" variant="contained" autoFocus>
					<PrintIcon/>{t("word-print")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}