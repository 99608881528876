import {identifyError} from "../errors";
import type {UserResult} from "./structures";
import type {GetUserOptions} from "./options";

export type {UserResult} from "./structures";
export type {GetUserOptions} from "./options";

const PATH = "api";
const PATH_USER = `${PATH}/user`;

export async function get(options?: GetUserOptions): Promise<UserResult> {
	const headers = {
		"Accept": "application/json",
		"Content-Type": "application/json"
	};

	const result = await fetch(`${PATH_USER}/`, {
		method: "GET", headers, credentials: "include"
	}).then(r => r.json());

	if (result && result.error) throw identifyError(result);
	return result.result;
}