export interface StoreResult {
    id: string
    name: string
    contact_groups?: StoreContactGroup[]
    enabled: boolean
    user_id: string
    address: StoreAddress
    is_external?: boolean
    guest_permissions?: string[]
}

export interface StoreContactGroup {
    id: string
    contact: string
    vendor: string
}

export interface StoreAddress {
    postal_code: string
    country: string
    state: string
    city: string
    address: string
    extra: string
    longitude: number
    latitude: number
}

export interface CreateResult {
    status: boolean
}

export const STORE_GUEST_PERMISSIONS = {
	LET_CHANGE_ORDER_STATUS: "letChangeOrderStatus", LET_RESUME_OR_PAUSE_MESSAGE_SESSION: "letResumeOrPauseMessageSession"
};