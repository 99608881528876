import React from "react";

export interface Properties {

}

export default function LoginPage(props: Properties) {
	return (
		<div>
            Blank Page
		</div>
	);
}