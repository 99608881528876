import {styled, TableCell, TableRow, Theme} from "@mui/material";
import {grey} from "@mui/material/colors";

export const StyledTableRow = styled(TableRow)({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: (theme: Theme) => theme.palette.action.hover
		}
	},
	hover: {
		"&:hover": {
			backgroundColor: (theme: Theme) => `${theme.palette.primary.light} !important`,
			cursor: "pointer"
		},
		"&:hover td": {
			color: (theme: Theme) => `${theme.palette.getContrastText(theme.palette.primary.light)} !important`
		}
	}
});

export const StyledTableCell = styled(TableCell)({
	head: {
		backgroundColor: grey[900],
		color: (theme: Theme) => theme.palette.getContrastText(grey[900])
	}
});