import {useEffect, useRef} from "react";

export default function useInterval(callback: () => void, delay: (number | null)) {
	const saved_callback = useRef(callback);

	useEffect(() => {
		saved_callback.current = callback;
	}, [callback]);

	useEffect(() => {
		if (delay === null) return;
		const id = setInterval(() => saved_callback.current(), delay);
		return () => clearInterval(id);
	}, [delay]);
}