import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

import transcriptEN from "../../../config/const/transcripts/en-US.json";
import transcriptPT from "../../../config/const/transcripts/pt-BR.json";

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			"en-US": {translations: transcriptEN},
			"pt-BR": {translations: transcriptPT}
		},
		lng: "en-US",
		fallbackLng: "en-US",
		debug: false,
		ns: ["translations"],
		defaultNS: "translations",
		keySeparator: ".",
		interpolation: {
			escapeValue: false,
			formatSeparator: ","
		},
		react: {
			useSuspense: true,
			bindI18n: "languageChanged loaded",
			nsMode: "default"
		}
	});

export default i18n;