import {identifyError} from "../errors";

import {LoginResult, LogoutResult} from "./structures";
import {LoginOptions, LogoutOptions} from "./options";

export type {LoginOptions, LogoutOptions} from "./options";

const PATH = "api/user/auth";

export async function login(options: LoginOptions): Promise<LoginResult> {
	const headers = {
		"Accept": "application/json",
		"Content-Type": "application/json"
	};
	const body = JSON.stringify({
		email: options.user, password: options.password
	});

	const result = await fetch(`${PATH}/login`, {
		method: "POST", headers, body, credentials: "include"
	}).then(r => r.json());

	if (result && result.error) throw identifyError(result);
	return result.result;
}

export async function logout(options?: LogoutOptions): Promise<LogoutResult> {
	const headers = {
		"Accept": "application/json",
		"Content-Type": "application/json"
	};
	const body = JSON.stringify({});

	const result = await fetch(`${PATH}/logout`, {
		method: "POST", headers, body
	}).then(r => r.json());

	if (result && result.error) throw identifyError(result);
	return {status: result.status};
}