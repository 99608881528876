import {
	Box, createTheme, Table, TableBody, TableContainer, TableFooter, TablePagination, ThemeProvider, Typography,
	Tooltip
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as locales from "@mui/material/locale";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

import {OrderCollection} from "../../../../services/actions/actions";
import SortedTableHead from "./sorted-table-head";

import {StyledTableCell, StyledTableRow} from "./styles";
import {getComparator, stableSort} from "./sorting";

import {toCurrency} from "../../../../utils/misc";

export interface Properties {
    orders: OrderCollection.OrderResult[]
    pagination: Pagination
    onChangePaginationPage(page: number): void
    onChangePaginationCount(count: number): void
    onSelectItem(id: string): void
}

interface Pagination {
    page: number
    count: number
    options: number[]
}

type SupportedLocales = keyof typeof locales;

export type OrderItemType = OrderCollection.OrderResult;

export interface SortItem {
    id: string
    customer_name: string
    customer_address: OrderCollection.Address
    status: OrderCollection.OrderStatus
    payment: number
    created_at: Date
}

export type SortKeyTypes = keyof SortItem;
export type SortOrderTypes = "asc" | "desc";

function mapOrdersToSortItems(orders: OrderItemType[]): SortItem[] {
	return orders.map(item => ({
		id: item.id,
		customer_name: item.info.customer.name,
		customer_address: item.info.customer.address,
		status: item.status,
		payment: item.payment.value,
		created_at: item.created_at
	}));
}

function parseAddress(address: OrderCollection.Address) {
	return `${address.address} - ${address.city} - ${address.state} - ${address.country} - ${address.postal_code}`;
}

export default function OrderTable(props: Properties) {
	const {t, i18n} = useTranslation();

	const [order_items, setOrderItems] = useState<OrderItemType[]>(props.orders);

	const [sort_key, setSortKey] = useState<SortKeyTypes>("id");
	const [sort_order, setSortOrder] = useState<SortOrderTypes>("asc");

	useEffect(() => {
		setOrderItems(props.orders);
	}, [props.orders]);

	const handleChangePaginationPage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
		props.onChangePaginationPage(page);
	};

	const handleChangePaginationCount = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		props.onChangePaginationCount(parseInt(event.target.value, 10));
		props.onChangePaginationPage(0);
	};

	const handleOnSelectItem = (id: string) => {
		props.onSelectItem(id);
	};

	const handleOnSelectSort = (sort_key: SortKeyTypes, sort_order: SortOrderTypes) => {
		setSortKey(sort_key);
		setSortOrder(sort_order);
	};

	const begin_index = (props.pagination.page * props.pagination.count);
	const end_index = (props.pagination.page * props.pagination.count) + props.pagination.count;

	const items = mapOrdersToSortItems(order_items);
	const sorted_items = stableSort(items, getComparator(sort_key, sort_order));

	return (
		<React.Fragment>
			<TableContainer>
				<Table>
					<SortedTableHead sort_key={sort_key} sort_order={sort_order}
						onSelectSort={handleOnSelectSort}
					/>
					<TableBody>
						{sorted_items.slice(begin_index, end_index).map((item, index) => (
							<TableBodyRow key={`table-body-row-${item.id}`} item={item}
								onSelect={() => handleOnSelectItem(item.id)}
							/>
						))}
						{!order_items.length && (
							<StyledTableRow>
								<StyledTableCell colSpan={6}>
									<Box sx={(theme) => ({display: "flex", justifyContent: "center", color: theme.palette.text.disabled})}>
										{t("empty-orders")}
									</Box>
								</StyledTableCell>
							</StyledTableRow>
						)}
					</TableBody>
					<TableFooter>
						<StyledTableRow>
							<ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[i18n.language.replace("-", "") as SupportedLocales])}>
								<TablePagination
									count={order_items.length}
									page={props.pagination.page}
									rowsPerPage={props.pagination.count}
									rowsPerPageOptions={props.pagination.options}
									SelectProps={{native: true}}
									onPageChange={handleChangePaginationPage}
									onRowsPerPageChange={handleChangePaginationCount}
									ActionsComponent={TablePaginationActions}
								/>
							</ThemeProvider>
						</StyledTableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</React.Fragment>
	);
}

function TableBodyRow(props: {item: SortItem, onSelect: () => void}) {
	const {t, i18n} = useTranslation();

	const address = parseAddress(props.item.customer_address);

	return (
		<StyledTableRow key={`order-props.item-${props.item.id}`} hover
			onClick={(e) => props.onSelect()}
		>
			<StyledTableCell><Tooltip title={props.item.id}><Box>{props.item.id.slice(0, 6)}...</Box></Tooltip></StyledTableCell>
			<StyledTableCell>{props.item.customer_name}</StyledTableCell>
			<StyledTableCell sx={{maxWidth: "300px"}}>
				<Tooltip title={address}><Box><Typography variant="body2" noWrap>{address}</Typography></Box></Tooltip>
			</StyledTableCell>
			<StyledTableCell>{t(`order-status-${props.item.status}`)}</StyledTableCell>
			<StyledTableCell>{toCurrency(props.item.payment)}</StyledTableCell>
			<StyledTableCell><Typography variant="body2" noWrap>{new Date(props.item.created_at).toLocaleString(i18n.language)}</Typography></StyledTableCell>
		</StyledTableRow>
	);
}