export function toCurrency(value: number, currency: (string | undefined) = "BRL", locales: (string | undefined) = "pt-BR") {
	if (isNaN(value)) value = 0;

	const options: Intl.NumberFormatOptions = {
		maximumFractionDigits: 2,
		minimumFractionDigits: (value % 1 === 0) ? 0 : 2,
		style: "currency",
		currency: currency,
		currencyDisplay: "symbol"
	};

	return value.toLocaleString(locales, options);
}