import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
	Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip
} from "@mui/material";
import {
	KeyboardArrowUp as KeyboardArrowUpIcon, KeyboardArrowDown as KeyboardArrowDownIcon
} from "@mui/icons-material";

import {OrderCollection} from "../../../../services/actions/actions";

import {toCurrency} from "../../../../utils/misc";

import ItemTable from "./item-table";
import {StyledTableCell, StyledTableRow} from "./styles";

export interface Properties {
    products: OrderCollection.OrderProduct[]
}

export function ProductTable(props: Properties) {
	const {t, i18n} = useTranslation();

	return (
		<TableContainer sx={(theme) => ({marginTop: theme.spacing(2), maxHeight: theme.spacing(50)})}>
			<Table size="small" stickyHeader>
				<TableHead>
					<StyledTableRow>
						<StyledTableCell>{t("order-table-id")}</StyledTableCell>
						<StyledTableCell>{t("order-product-name")}</StyledTableCell>
						<StyledTableCell>{t("order-table-payment-value")}</StyledTableCell>
						<StyledTableCell>{t("order-product-description")}</StyledTableCell>
						<StyledTableCell>{t("order-product-items")}</StyledTableCell>
					</StyledTableRow>
				</TableHead>
				<TableBody>
					{props.products.map((item, index) => (
						<ProductRow key={`product-table-${item.id}-${index}`} item={item} index={index}/>
					))}
					{props.products.length == 0 && (
						<StyledTableRow>
							<StyledTableCell colSpan={6}/>
						</StyledTableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

function ProductRow(props: {item: OrderCollection.OrderProduct, index: number}) {
	const [is_open, setIsOpen] = useState<boolean>(false);

	return (
		<React.Fragment>
			<StyledTableRow sx={(theme) => ({
				backgroundColor: (props.index % 2) != 0 ? theme.palette.action.hover : undefined
			})}>
				<StyledTableCell><Tooltip title={props.item.id}><Box>{props.item.id.slice(0, 6)}...</Box></Tooltip></StyledTableCell>
				<StyledTableCell>{props.item.info.name}</StyledTableCell>
				<StyledTableCell>{toCurrency(props.item.payment_value)}</StyledTableCell>
				<StyledTableCell>{props.item.info.description != "" ? props.item.info.description : "---"}</StyledTableCell>
				<StyledTableCell>
					<IconButton size="small" onClick={() => setIsOpen(!is_open)}>
						{is_open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
					</IconButton>
				</StyledTableCell>
			</StyledTableRow>
			<TableRow>
				<StyledTableCell style={{padding: 0}} colSpan={6}>
					<Collapse in={is_open} timeout="auto" unmountOnExit>
						<Box>
							<ItemTable items={props.item.items}/>
						</Box>
					</Collapse>
				</StyledTableCell>
			</TableRow>
		</React.Fragment>
	);
}