import {useEffect, useRef} from "react";

export default function useIsMounted() {
	const is_mounted = useRef(false);

	useEffect(() => {
		is_mounted.current = true;
		return () => {
			is_mounted.current = false;
		};
	}, []);
	return () => is_mounted.current;
}