import React from "react";
import {CssBaseline} from "@mui/material";
import {I18nextProvider} from "react-i18next";

import AuthProvider from "../contexts/auth/auth";
import StoreProvider from "../contexts/store/store";
import ThemeProvider from "../contexts/theme/theme";
import Router from "./router";
import i18n from "./internalization";

import "./style/application.css";

export interface Settings {
    brand_name: string
    network_domain: string
}

export interface Properties {
    setts: Settings
}

export interface State {

}

export default function Application(props: Properties) {
	return (
		<div>
			<I18nextProvider i18n={i18n}>
				<AuthProvider setts={{domain: props.setts.network_domain}}>
					<ThemeProvider>
						<CssBaseline/>
						<StoreProvider setts={{domain: props.setts.network_domain}}>
							<Router {...props}/>
						</StoreProvider>
					</ThemeProvider>
				</AuthProvider>
			</I18nextProvider>
		</div>
	);
}