import {identifyError} from "../errors";
import {SessionResult} from "./structures";
import {
	GetSessionByContactGroupIdOptions, ResumeOrPauseSessionOptions
} from "./options";

export type {
	GetSessionByContactGroupIdOptions, ResumeOrPauseSessionOptions
} from "./options";
export type {SessionResult} from "./structures";

const PATH = "api";
const PATH_MESSAGE = `${PATH}/message`;
const PATH_SESSION = `${PATH_MESSAGE}/session`;

export async function listByContactGroupId(options: GetSessionByContactGroupIdOptions): Promise<SessionResult[]> {
	const headers = {
		"Accept": "application/json",
		"Content-Type": "application/json"
	};

	const params: any = {store_id: options.store_id, contact_group_id: options.contact_group_id};
	const query_params = new URLSearchParams(params);

	const result = await fetch(`${PATH_SESSION}/list?${query_params}`, {
		method: "GET", headers, credentials: "include"
	}).then(r => r.json());

	if (result && result.error) throw identifyError(result);
	return result.result || [];
}

export async function resumeOrPauseSession(options: ResumeOrPauseSessionOptions): Promise<SessionResult[]> {
	const headers = {
		"Accept": "application/json",
		"Content-Type": "application/json"
	};

	const body = {store_id: options.store_id, session_id: options.session_id, is_on_hold: options.is_on_hold};
	const result = await fetch(`${PATH_SESSION}/resume-or-pause`, {
		method: "PATCH", body: JSON.stringify(body), headers, credentials: "include"
	}).then(r => r.json());

	if (result && result.error) throw identifyError(result);
	return result.result || [];
}