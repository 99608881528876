import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router";
import {
	AppBar, Toolbar, Button, IconButton, Menu, Drawer, useTheme, Hidden, Typography, Divider,
	List, ListItem, Grid, ListItemText, ListItemIcon, Collapse, Tooltip, MenuItem,
	FormControl, InputLabel, Select, Box, Autocomplete, TextField, TextFieldProps
} from "@mui/material";
import {
	Menu as MenuIcon, Brightness7 as SunIcon, Brightness4 as MoonIcon,
	Store as StoreIcon, Close as CloseIcon, Receipt as ReceiptIcon,
	HelpOutline as HelpOutlineIcon, AccountCircle as AccountCircleIcon,
	ChatBubble as ChatBubbleIcon
} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

import vow from "../../../../pkg/utils/vow";

import {ROUTE_ITEMS} from "../../application/router";
import {useAuth} from "../../contexts/auth/auth";
import {THEME_DARK, useTheme as useCustomTheme} from "../../contexts/theme/theme";

import {StoreCollection} from "../../../services/actions/actions";

import {useStore} from "../../contexts/store/store";
import {STORE_GUEST_PERMISSIONS} from "../../../services/actions/store/structures";

const DRAWER_WIDTH = 240;

export interface Properties {
	children: React.ReactNode
}

export function AppBarLayout(props: Properties) {
	const {t, i18n} = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const theme = useTheme();

	const [account_menu_element, setAccountMenuElement] = useState<null | HTMLElement>(null);

	const auth_context = useAuth();
	if (!auth_context) throw new Error("Context must be called inside provider");

	const is_logged = auth_context.isLogged();

	const [drawer_temporary_status, setDrawerTemporaryStatus] = useState<boolean>(false);
	const [drawer_persistent_status, setDrawerPersistentStatus] = useState<boolean>(is_logged);

	const store_context = useStore();
	if (!store_context) throw new Error("Context must be called inside provider");

	const theme_context = useCustomTheme();
	if (!theme_context) throw new Error("Context must be called inside provider");

	const [stores, setStores] = useState<StoreCollection.StoreResult[]>([]);

	useEffect(() => {
		function updateSize() {
			setDrawerTemporaryStatus(false);
			setDrawerPersistentStatus(false);
		}
		window.addEventListener("resize", updateSize);
		return () => {window.removeEventListener("resize", updateSize);};
	}, []);

	useEffect(() => {
		if (is_logged && !stores.length) {
			const handler = async () => {
				const result = await vow.handle(store_context.getStores());
				if (result instanceof Error) return;

				setStores([...result.stores, ...result.external_stores]);
			};
			handler();
		}
	}, [is_logged]);

	useEffect(() => {
		setDrawerPersistentStatus(is_logged);
	}, [is_logged]);

	const handleSelectStore = (value?: string) => {
		if (!value) navigate(ROUTE_ITEMS.home[0]);
		store_context.setSelectedStore(value ? stores.find(v => v.id === value) : undefined);
	};

	const handleLogout = () => {
		auth_context.logout();
		navigate(ROUTE_ITEMS.home[0]);
		setAccountMenuElement(null);
	};

	const selected_store = store_context.getSelectedStore();

	const drawer_content = (
		<React.Fragment>
			<Box sx={(theme) => ({
				...theme.mixins.toolbar, display: "flex", flexDirection: "column",
				justifyContent: "center", width: `${DRAWER_WIDTH}px`, maxWidth: `${DRAWER_WIDTH}px`
			})}>
				<Typography variant="h5" align="center">{t("quickbot")}</Typography>
			</Box>
			<Divider/>
			<Autocomplete
				sx={{display: "flex", flexWrap: "nowrap", marginTop: (theme) => theme.spacing(2), marginLeft: (theme) => theme.spacing(1), marginRight: (theme) => theme.spacing(1)}}
				value={selected_store ? {id: selected_store.id, name: selected_store.name} : null}
				onChange={(e, v) => handleSelectStore(v?.id || "")}
				id="select-store"
				options={stores?.map(v => ({id: v.id, name: `${v.name}${v.is_external ? ` (${t("external-store-guest")})` : ""}`})) || []}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				getOptionLabel={(option) => option.name}
				renderInput={(params) => <TextField {...(params as TextFieldProps)} label={t("store")}/>}
			/>
			<List component="nav" sx={{marginTop: (theme) => theme.spacing(2)}}>
				<React.Fragment>
					{selected_store && (
						<React.Fragment>
							<ListItem button selected={ROUTE_ITEMS.order.includes(location.pathname)}
								onClick={() => navigate(ROUTE_ITEMS.order[0])}
							>
								<ListItemIcon sx={{minWidth: (theme) => theme.spacing(4)}}><ReceiptIcon/></ListItemIcon>
								<ListItemText primary={t("orders")}/>
							</ListItem>
							{(!selected_store.is_external || selected_store.guest_permissions?.find(v => v === STORE_GUEST_PERMISSIONS.LET_RESUME_OR_PAUSE_MESSAGE_SESSION)) &&
								<ListItem button selected={ROUTE_ITEMS.chat.includes(location.pathname)}
									onClick={() => navigate(ROUTE_ITEMS.chat[0])}
								>
									<ListItemIcon sx={{minWidth: (theme) => theme.spacing(4)}}><ChatBubbleIcon/></ListItemIcon>
									<ListItemText primary={t("chats")}/>
								</ListItem>
							}
						</React.Fragment>
					)}
				</React.Fragment>
			</List>
		</React.Fragment>
	);

	return (
		<Box>
			<AppBar sx={(theme) => ({
				[theme.breakpoints.up("sm")]: {
					transition: theme.transitions.create(["margin", "width"], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen
					}),
					width: drawer_persistent_status ? `calc(100% - ${DRAWER_WIDTH}px) !important` : undefined,
					marginLeft: drawer_persistent_status ? `${DRAWER_WIDTH}px` : undefined
				}
			})} position="fixed">
				<Toolbar>
					{is_logged &&
						<React.Fragment>
							<Box sx={(theme) => ({
								[theme.breakpoints.up("sm")]: {
									display: "none"
								}
							})}>
								<IconButton sx={{marginRight: (theme) => theme.spacing(2)}}
									edge="start" color="inherit"
									onClick={() => setDrawerTemporaryStatus(true)}
								>
									<MenuIcon/>
								</IconButton>
							</Box>
							<Box sx={(theme) => ({
								[theme.breakpoints.down("sm")]: {
									display: "none"
								}
							})}>
								<IconButton sx={{marginRight: (theme) => theme.spacing(2)}}
									edge="start" color="inherit"
									onClick={() => setDrawerPersistentStatus(!drawer_persistent_status)}
								>
									<MenuIcon/>
								</IconButton>
							</Box>
						</React.Fragment>
					}
					<Button color="inherit" onClick={() => navigate(ROUTE_ITEMS.home[0])}>
						{t("app-bar-home")}
					</Button>
					<Box sx={{display: "flex", marginLeft: "auto"}}>
						<IconButton sx={{marginRight: (theme) => theme.spacing(2)}}
							edge="end" color="inherit"
							onClick={() => theme_context.toggleTheme()}
						>
							{theme_context.theme_type == THEME_DARK ? <SunIcon/> : <MoonIcon/>}
						</IconButton>
						{is_logged &&
							<React.Fragment>
								<IconButton sx={{marginRight: (theme) => theme.spacing(2)}}
									edge="start" color="inherit"
									onClick={(e) => setAccountMenuElement(e.currentTarget)}
								>
									<AccountCircleIcon/>
								</IconButton>
								<Menu keepMounted open={!!account_menu_element}
									anchorEl={account_menu_element}
									anchorOrigin={{vertical: "bottom", horizontal: "left"}}
									transformOrigin={{vertical: "top", horizontal: "left"}}
									onClose={() => setAccountMenuElement(null)}
								>
									<MenuItem onClick={handleLogout}>{t("app-bar-logout")}</MenuItem>
								</Menu>
							</React.Fragment>
						||
							<Button color="inherit" onClick={() => navigate(ROUTE_ITEMS.login[0])}>
								{t("app-bar-login")}
							</Button>
						}
					</Box>
				</Toolbar>
			</AppBar>
			{is_logged &&
				<Box component="nav" sx={(theme) => ({
					[theme.breakpoints.up("sm")]: {
						width: `${DRAWER_WIDTH}px`,
						flexShrink: 0
					}
				})}>
					<Box sx={(theme) => ({
						[theme.breakpoints.up("sm")]: {
							display: "none"
						}
					})}>
						<Drawer
							variant="temporary" anchor={theme.direction === "rtl" ? "right" : "left"}
							open={drawer_temporary_status}
							onClose={() => setDrawerTemporaryStatus(false)}
							sx={{
								paper: {
									width: `${DRAWER_WIDTH}px`,
									overflow: "hidden"
								}
							}}
							ModalProps={{keepMounted: true}}
						>
							{drawer_content}
						</Drawer>
					</Box>
					<Box sx={(theme) => ({
						[theme.breakpoints.down("sm")]: {
							display: "none"
						}
					})}>
						<Drawer
							variant="persistent" anchor="left"
							open={drawer_persistent_status}
							onClose={() => setDrawerPersistentStatus(false)}
							sx={{
								paper: {
									width: `${DRAWER_WIDTH}px`,
									overflow: "hidden"
								}
							}}
						>
							{drawer_content}
						</Drawer>
					</Box>
				</Box>
			}
			<Box component="main" sx={() => ({
				flexGrow: 1,
				minHeight: "100vh",
				[theme.breakpoints.up("sm")]: {
					transition: drawer_persistent_status ? theme.transitions.create("margin", {
						easing: theme.transitions.easing.easeOut,
						duration: theme.transitions.duration.enteringScreen
					}) : theme.transitions.create("margin", {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen
					}),
					marginLeft: drawer_persistent_status ? `${DRAWER_WIDTH}px` : 0
				}
			})}>
				<Box sx={(theme) => theme.mixins.toolbar}></Box>
				<Box sx={(theme) => ({padding: theme.spacing(3, 3, 3)})}>{props.children}</Box>
			</Box>
		</Box>
	);
}