import React from "react";
import {Navigate, Route, RouteProps} from "react-router-dom";

import {ROUTE_ITEMS} from "../../application/router";
import {useAuth} from "../../contexts/auth/auth";
import {AppBarLayout} from "../app-bar/component";

export default function PrivateRoute(props: RouteProps) {
	const auth_context = useAuth();
	if (!auth_context) throw new Error("Context must be called inside provider");

	const element = props.element ?? <React.Fragment></React.Fragment>;

	const is_logged = auth_context.isLogged();
	if (!is_logged) return <Navigate to={ROUTE_ITEMS.home[0]}/>;

	return <AppBarLayout>{element}</AppBarLayout>;
}