import {identifyError} from "../errors";
import {PaginationResult} from "../structures";
import {OrderResult, CreateResult, OrderProduct, OrderProductItem, OrderProductItemPick, Address, OrderStatus} from "./structures";
import {
	GetOrdersByStoreOptions, UpdateOrderOptions
} from "./options";

export type {
	GetOrdersByStoreOptions, UpdateOrderOptions
} from "./options";
export type {OrderResult, CreateResult, OrderProduct, OrderProductItem, OrderProductItemPick, Address, OrderStatus} from "./structures";

const PATH = "api";
const PATH_ORDER = `${PATH}/order`;

export async function paginate(options: GetOrdersByStoreOptions): Promise<PaginationResult<OrderResult[]>> {
	const headers = {
		"Accept": "application/json",
		"Content-Type": "application/json"
	};

	const params: any = {store_id: options.store_id, checkpoint: options.checkpoint};
	const query_params = new URLSearchParams(params);

	if (options.status != null && options.status.length) {
		options.status.forEach(i => query_params.append("status", i));
	}

	const result = await fetch(`${PATH_ORDER}/paginate?${query_params}`, {
		method: "GET", headers, credentials: "include"
	}).then(r => r.json());

	if (result && result.error) throw identifyError(result);
	return result.result || [];
}

export async function updateStatus(options: UpdateOrderOptions): Promise<OrderResult> {
	const headers = {
		"Accept": "application/json",
		"Content-Type": "application/json"
	};
	const body = JSON.stringify(options);

	const result = await fetch(`${PATH_ORDER}/status`, {
		method: "PATCH", headers, credentials: "include", body
	}).then(r => r.json());

	if (result && result.error) throw identifyError(result);
	return result;
}