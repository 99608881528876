import {identifyError} from "../errors";
import {StoreResult, CreateResult} from "./structures";
import {
	GetStoresByUserOptions, CreateStoreOptions,
	UpdateStoreOptions
} from "./options";

export type {
	GetStoresByUserOptions, CreateStoreOptions, UpdateStoreOptions
} from "./options";
export type {StoreResult, CreateResult} from "./structures";

const PATH = "api";
const PATH_STORE = `${PATH}/store`;

export async function get(options?: GetStoresByUserOptions): Promise<{stores: StoreResult[], external_stores: StoreResult[]}> {
	const headers = {
		"Accept": "application/json",
		"Content-Type": "application/json"
	};

	const result = await fetch(`${PATH_STORE}`, {
		method: "GET", headers, credentials: "include"
	}).then(r => r.json());

	if (result && result.error) throw identifyError(result);
	return result.result || {stores: [], external_stores: []};
}

export async function update(options: UpdateStoreOptions): Promise<StoreResult> {
	const headers = {
		"Accept": "application/json",
		"Content-Type": "application/json"
	};
	const body = JSON.stringify(options);

	const result = await fetch(`${PATH_STORE}`, {
		method: "PATCH", headers, credentials: "include", body
	}).then(r => r.json());

	if (result && result.error) throw identifyError(result);
	return result;
}