import React, {useContext, useEffect, useState} from "react";

import vow from "../../../../pkg/utils/vow";

import {StoreCollection} from "../../../../ctx/services/actions/actions";
import {useAuth} from "../auth/auth";

export interface Settings {

}

export interface Properties {
    setts: Settings
    children: React.ReactNode
}

export type ProviderType = {
    selected_store: (StoreCollection.StoreResult | undefined)
    setSelectedStore(store?: StoreCollection.StoreResult): void
    getSelectedStore(): (StoreCollection.StoreResult | undefined)
	getStores(): Promise<{stores: StoreCollection.StoreResult[], external_stores: StoreCollection.StoreResult[]}>
};
export type ProviderDefaultType = undefined;
export type ContextType = ProviderType | ProviderDefaultType;

export const Context = React.createContext<ContextType>(undefined);

const STORAGE_SELECTED_STORE = "@store/selected-store";

export default function StoreProvider(props: Properties) {
	const [selected_store, setSelectedStore] = useState<StoreCollection.StoreResult | undefined>();

	useEffect(() => {
		const data = localStorage.getItem(STORAGE_SELECTED_STORE);
		if (data) setSelectedStore(JSON.parse(data));
	}, []);

	const auth_context = useAuth();
	if (!auth_context) throw new Error("Context must be called inside provider");

	const getSelectedStore = () => {
		return selected_store;
	};

	const changeSelectedStore = (store: (StoreCollection.StoreResult | undefined)) => {
		setSelectedStore(store);
		sessionStorage.setItem(STORAGE_SELECTED_STORE, JSON.stringify(store));
	};

	const getStores = async () => {
		const result = await vow.handle(StoreCollection.get());
		if (result instanceof Error) {
			const error = new Error(`Can't get store(s): ${result.message}`);
			error.name = result.name;
			throw error;
		}

		const payload = result || {stores: [], external_stores: []};
		payload.external_stores = payload.external_stores.map(v => ({...v, is_external: true}));

		return payload;
	};

	return (
		<Context.Provider value={{
			selected_store, setSelectedStore: changeSelectedStore,
			getSelectedStore, getStores
		}}>
			{props.children}
		</Context.Provider>
	);
}

export function useStore() {
	return useContext(Context);
}