import {useState, useEffect} from "react";

export function useAudioEffect(path: string): ([boolean, () => void]) {
	const [audio] = useState(new Audio(path));
	const [playing, setPlaying] = useState(false);

	const play = () => {
		if (!playing) setPlaying(true);
	};

	useEffect(() => {
		if (playing) {
			audio.currentTime = 0;
			audio.play();
		}
	}, [playing]);

	useEffect(() => {
		audio.addEventListener("ended", () => setPlaying(false));
		return () => {setPlaying(false);};
	}, []);

	return [playing, play];
}

export function useAudioPlayer(path: string): ([boolean, () => void]) {
	const [audio] = useState(new Audio(path));
	const [playing, setPlaying] = useState(false);

	const toggle = () => setPlaying(!playing);

	useEffect(() => {
		playing ? audio.play() : audio.pause();
	}, [playing]);

	useEffect(() => {
		audio.addEventListener("ended", () => setPlaying(false));
		return () => {setPlaying(false);};
	}, []);

	return [playing, toggle];
}