import React from "react";
import {createRoot} from "react-dom/client";
import {Helmet} from "react-helmet";

import logger from "../pkg/utils/logger";

import config_loader from "../config/config-loader";

import Application from "../ctx/client/application/application";

const main = async function() {
	logger.enable();
	logger.info("Starting application");

	logger.info("Loading config/environment variables");
	const configs = config_loader();

	logger.info("Loading services");

	logger.info("Rendering application");

	const root = createRoot(document.getElementById("app-root") as HTMLElement);
	root.render(
		<React.Fragment>
			<Helmet>
				<title>{configs.brand.name}</title>
			</Helmet>
			<Application
				setts={{
					brand_name: configs.brand.name,
					network_domain: configs.network.domain
				}}
			/>
		</React.Fragment>
	);
};

main();