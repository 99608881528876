import {SortItem, SortKeyTypes, SortOrderTypes} from "./order-table";

export function getComparator(sort_key: SortKeyTypes, sort_order: SortOrderTypes) {
	if (sort_order === "desc") return (a: SortItem, b: SortItem) => descendingComparator(a, b, sort_key);
	return (a: SortItem, b: SortItem) => -descendingComparator(a, b, sort_key);
}

export function descendingComparator(order_x: SortItem, order_y: SortItem, sort_key: SortKeyTypes) {
	if (order_y[sort_key] < order_x[sort_key]) {
		return -1;
	}
	if (order_y[sort_key] > order_x[sort_key]) {
		return 1;
	}
	return 0;
}

export function stableSort(items: SortItem[], comparator: (a: SortItem, b: SortItem) => number) {
	const values = items.map((item, index) => [item, index] as [SortItem, number]).sort((x, y) => {
		const order = comparator(x[0], y[0]);
		if (order !== 0) return order;
		return x[1] - y[1];
	});
	return values.map((item) => item[0]);
}