import {TableCell, TableRow, Theme, styled} from "@mui/material";
import {grey} from "@mui/material/colors";

export const StyledTableRow = styled(TableRow)({});

export const StyledStripedTableRow = styled(TableRow)({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: (theme: Theme) => theme.palette.action.hover
		}
	}
});

export const StyledTableCell = styled(TableCell)({
	head: {
		backgroundColor: grey[900],
		color: (theme: Theme) => theme.palette.getContrastText(grey[900])
	}
});