import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {
	Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, Hidden,
	makeStyles, Paper, Snackbar, TextField, Typography, Box
} from "@mui/material";
import {useTranslation} from "react-i18next";

import vow from "../../../../pkg/utils/vow";
import logger from "../../../../pkg/utils/logger";
import {EMAIL_REGEX} from "../../../../pkg/utils/regex";

import {useAuth} from "../../contexts/auth/auth";
import {Errors} from "../../../../ctx/services/actions/actions";
import {ROUTE_ITEMS} from "../../application/router";
import useToast from "../../components/hooks/toast";
import useIsMounted from "../../components/hooks/mounted";

interface Properties {

}

interface FormField <T = string> {
    value: T
    error?: string
}

export default function LoginPage(props: Properties) {
	const {t, i18n} = useTranslation();
	const navigate = useNavigate();

	const is_mounted = useIsMounted();

	const [email_field, setEmailField] = useState<FormField>({value: ""});
	const [password_field, setPasswordField] = useState<FormField>({value: ""});

	const [Toast, toastify] = useToast();

	const auth_context = useAuth();
	if (!auth_context) throw new Error("Context must be called inside provider");

	const handleSubmitForm = async (event: React.FormEvent) => {
		event.preventDefault();

		const email = email_field.value;
		const password = password_field.value;

		if (!validateFormFields()) {
			logger.error("Can't login");
			toastify({message: t("login-error-failed"), severity: "error"});
			return false;
		}

		const result = await vow.handle(auth_context.login({user: email, password}));
		if (result instanceof Error) {
			const error_index = "login-error-failed";

			logger.error(`Can't login: ${result.message}`);
			toastify({message: t(error_index), severity: "error"});

			setPasswordField({value: ""});
			return false;
		}

		if (is_mounted()) {
			toastify({message: t("login-success"), severity: "success"});
		}
		navigate(ROUTE_ITEMS.home[0]);
		return true;
	};

	const validateFormFields = (): boolean => {
		const email = email_field.value;
		const password = password_field.value;

		let status = true;

		if (!(email && email.length)) {
			setEmailField({value: "", error: t("login-error-email-empty")});
			status = false;
		} else if (!email.match(EMAIL_REGEX)) {
			setEmailField({value: "", error: t("login-error-email-invalid")});
			status = false;
		}

		if (!(password && password.length)) {
			setPasswordField({value: "", error: t("login-error-password-empty")});
			status = false;
		}
		return status;
	};

	const login_block = (
		<React.Fragment>
			<Typography component="h1" variant="h5">
				{t("login-header")}
			</Typography>
			<Box component="form"
				sx={(theme) => ({width: "100%", marginTop: theme.spacing(1)})}
				onSubmit={handleSubmitForm}>
				<TextField id="login-email" variant="outlined"
					margin="dense" required fullWidth name="email"
					label={t("login-email-placeholder")} autoComplete="login-email" autoFocus
					onChange={(e) => setEmailField({value: e.currentTarget.value})}
					value={email_field.value}
					error={!!email_field.error} helperText={email_field.error}
				/>
				<TextField id="login-password" variant="outlined"
					margin="dense" required fullWidth name="password"
					label={t("login-password-placeholder")} type="password" autoComplete="login-current-password"
					onChange={(e) => setPasswordField({value: e.currentTarget.value})}
					value={password_field.value}
					error={!!password_field.error} helperText={password_field.error}
				/>
				<Button sx={(theme) => ({margin: theme.spacing(3, 0, 2)})}
					type="submit" fullWidth variant="contained" color="primary"
				>
					{t("login-submit-button")}
				</Button>

			</Box>
		</React.Fragment>
	);

	return (
		<React.Fragment>
			<Toast/>
			<Container component="main" maxWidth="xs">
				<Box sx={(theme) => ({
					[theme.breakpoints.up("sm")]: {
						display: "none"
					}
				})}>
					<Box>{login_block}</Box>
				</Box>
				<Box sx={(theme) => ({
					[theme.breakpoints.down("sm")]: {
						display: "none"
					}
				})}>
					<Paper sx={(theme) => ({
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						padding: theme.spacing(3, 5)
					})}>
						{login_block}
					</Paper>
				</Box>
			</Container>
		</React.Fragment>
	);
}