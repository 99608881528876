import React, {useCallback, useEffect, useState} from "react";
import dayjs from "dayjs";
import {Navigate, useNavigate} from "react-router-dom";
import {
	useTheme, Container, Switch, Grid, CircularProgress, FormGroup, FormControlLabel,
	Button, IconButton, Alert, Box, Select, Autocomplete, TextField, Chip, Typography, Tooltip
} from "@mui/material";
import {PlayCircle as PlayCircleIcon, PauseCircle as PauseCircleIcon} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

import vow from "../../../../pkg/utils/vow";
import logger from "../../../../pkg/utils/logger";

import {useTheme as useCustomTheme} from "../../contexts/theme/theme";
import {MessageCollection} from "../../../services/actions/actions";
import {useStore} from "../../contexts/store/store";
import {useAuth} from "../../contexts/auth/auth";

import useInterval from "../../components/hooks/interval";
import {useAudioEffect} from "../../components/hooks/audio";
import useToast from "../../components/hooks/toast";

import {ROUTE_ITEMS} from "../../application/router";

import notification_sound from "../../../../config/const/assets/audio/notification.mp3";

export interface Properties {

}

const REFRESH_INTERVAL = 15000;

export default function OrderPage(props: Properties) {
	const {t, i18n} = useTranslation();
	const navigate = useNavigate();

	const [sessions, setSessions] = useState<MessageCollection.SessionResult[]>([]);
	const [is_loaded, setIsLoaded] = useState<boolean>(false);

	const [Toast, toastify] = useToast();

	const auth_context = useAuth();
	if (!auth_context) throw new Error("Context must be called inside provider");

	const store_context = useStore();
	if (!store_context) throw new Error("Context must be called inside provider");

	const theme = useTheme();

	const selected_store = store_context.getSelectedStore();
	if (selected_store == null) {
		return (<Navigate to={ROUTE_ITEMS.home[0]}/>);
	}

	const contact_groups = selected_store?.contact_groups ?? [];

	const getSessions = useCallback(async () => {
		const current_sessions = [];
		for (const contact_group of contact_groups) {
			const result = await vow.handle(MessageCollection.listByContactGroupId({
				store_id: selected_store.id, contact_group_id: contact_group.id
			}));
			if (result instanceof Error) {
				logger.error(`Can't get session(s): ${result.message}`);
				toastify({message: t("network-error"), severity: "error"});
				return;
			}
			if (!(result && result)) {
				logger.error("Can't get session(s)");
				toastify({message: t("network-error"), severity: "error"});
				return;
			}
			if (!result.length) break;

			current_sessions.push(...result);
		}

		setSessions(current_sessions);
	}, [selected_store]);

	useEffect(() => {
		(async () => {
			await getSessions();
			setIsLoaded(true);
		})();
	}, [selected_store, is_loaded]);

	useInterval(() => {
		refreshInterval();
	}, REFRESH_INTERVAL);

	const refreshInterval = () => {
		getSessions();
	};

	const handleResumeOrStopSession = async (session_id: string, is_on_hold: boolean) => {
		await vow.handle(MessageCollection.resumeOrPauseSession({
			store_id: selected_store.id, session_id, is_on_hold
		}));
		await getSessions();
	};

	if (!is_loaded) return (
		<Container component="main" maxWidth="sm">
			<Grid container direction="column" alignItems="center">
				<CircularProgress/>
			</Grid>
		</Container>
	);

	if (!sessions?.length) return (
		<Box sx={{display: "flex", justifyContent: "center"}}><Typography>{t("empty-sessions")}</Typography></Box>
	);

	const sorted_sessions = sessions.sort((a, b) => b.on_hold_at - a.on_hold_at);

	return (
		<React.Fragment>
			<Toast/>
			<Box sx={{width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "center", rowGap: theme.spacing(1)}}>
				{sorted_sessions.map(v => {
					const on_hold = v.on_hold_at > 0;
					return (
						<Box sx={{display: "flex", flexBasis: "100%"}}>
							<Box sx={{
								width: "fit-content",
								padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
								border: `1px solid ${theme.palette.text.primary}`, borderRadius: "15px"
							}}>
								<Box sx={{display: "flex", alignItems: "center", columnGap: "10px"}}>
									<Typography>{t("contact")}: {v.source}</Typography>
									<Box sx={{width: "14px", height: "14px", borderRadius: "20px", backgroundColor: on_hold ? "red" : "green"}}></Box>
								</Box>
								<Tooltip title={on_hold ? t("resume-on-hold") : t("pause-on-hold")}>
									<IconButton onClick={() => {
										handleResumeOrStopSession(v.id, !on_hold);
									}}>
										{on_hold &&
											<PlayCircleIcon sx={{width: "32px", height: "32px"}}/>
											||
											<PauseCircleIcon sx={{width: "32px", height: "32px"}}/>
										}
									</IconButton>
								</Tooltip>
							</Box>
						</Box>
					);
				})}
			</Box>
		</React.Fragment>
	);
}