import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Collapse, IconButton, Table, TableBody, TableContainer, TableCell, TableRow, List, ListItem, Typography, TableHead} from "@mui/material";
import {
	KeyboardArrowUp as KeyboardArrowUpIcon, KeyboardArrowDown as KeyboardArrowDownIcon
} from "@mui/icons-material";

import {OrderCollection} from "../../../../services/actions/actions";

import {toCurrency} from "../../../../utils/misc";

export interface Properties {
    order: OrderCollection.OrderResult
    ref: React.RefObject<HTMLDivElement>
}

const formatAddress = (address: OrderCollection.Address) => {
	return `${address.address} - ${address.city} - ${address.state} - ${address.country} - ${address.postal_code}`;
};

const PrintableOrder = React.forwardRef((props: Properties, ref: React.ForwardedRef<HTMLDivElement | null>) => {
	const {t, i18n} = useTranslation();

	const order = props.order;

	return (
		<Box sx={{display: "none"}}>
			<Box sx={{color: "black", padding: "12px 16px"}} ref={ref}>
				<Box sx={{width: "100%"}}>
					<Typography variant="body1">{t("order-modal-title", {id: order.id})}</Typography>
					<Box sx={{display: "flex", justifyContent: "space-between"}}>
						<Typography variant="body1">{t("order-table-customer") + ": " + order.info.customer.name}</Typography>
						<Typography variant="body1">{new Date(order.created_at).toLocaleString(i18n.language)}</Typography>
					</Box>
					<Typography variant="body1">{`${t("order-table-status")}: ${t(`order-status-${order.status}`)}`}</Typography>
					<Typography variant="body1">{t("order-table-method") + ": " + t(`order-table-method-type-${order.method}`)}</Typography>
					<Typography variant="body1">{t("order-table-address") + ": " + formatAddress(order.info.customer.address)}</Typography>
					<br/><br/>
					<Table size="small" sx={{backgrounColor: "white", tableLayout: "fixed", width: "100%"}}>
						<TableHead>
							<TableRow>
								<TableCell><Typography sx={{color: "black"}}>{t("order-table-id")}</Typography></TableCell>
								<TableCell><Typography sx={{color: "black"}}>{t("order-product-name")}</Typography></TableCell>
								<TableCell><Typography sx={{color: "black"}}>{t("order-table-payment-value")}</Typography></TableCell>
								<TableCell><Typography sx={{color: "black"}}>{t("order-product-description")}</Typography></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{order.products.map((product, index) => (
								<React.Fragment>
									<TableRow>
										<TableCell><Typography sx={{color: "black"}}>{product.id.slice(0, 6)}...</Typography></TableCell>
										<TableCell><Typography sx={{color: "black"}}>{product.info.name}</Typography></TableCell>
										<TableCell><Typography sx={{color: "black"}}>{toCurrency(product.payment_value)}</Typography></TableCell>
										<TableCell><Typography sx={{color: "black"}}>{product.info.description != "" ? product.info.description : "---"}</Typography></TableCell>
									</TableRow>
									{product.items?.length && <TableRow>
										<TableCell colSpan={4}>
											<Box sx={{display: "flex", flexWrap: "wrap", rowGap: "12px", padding: "8px 12px"}}>
												{product.items.map((item, index) => (
													<Box sx={{flexBasis: "33%", flexGrow: 1}}>
														<Typography sx={{color: "black"}}>{`${index + 1}. ${item.title} - ${toCurrency(item.payment_value)}`}</Typography>
														{item.picks.length && (<Box sx={{marginLeft: "24px"}}>
															{item.picks.map((pick) => (
																<Typography sx={{color: "black"}}>{`• ${pick.quantity}x - ${pick.name} - ${toCurrency(pick.payment_value)}`}</Typography>
															))}
														</Box>)}
													</Box>
												))}
											</Box>
										</TableCell>
									</TableRow>}
								</React.Fragment>
							))}
						</TableBody>
					</Table>
					<br/><br/>
					<Box sx={{display: "flex", marginTop: (theme) => theme.spacing(2), justifyContent: "space-between"}}>
						<Typography variant="body1">
							{t("order-table-payment-value") + ": " + toCurrency(order.payment.value)}
						</Typography>
						<Typography variant="body1">
							{t("order-table-payment-method") + ": " + t(`order-table-payment-method-type-${order.payment.method}`)}
						</Typography>
						{!!order.payment.change_for && (
							<Typography variant="body1">
								{t("order-table-payment-change-for") + ": " + toCurrency(order.payment.change_for)}
							</Typography>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	);
});

export default PrintableOrder;