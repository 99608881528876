export interface BrandConfiguration {
    name: string
}

export interface NetworkConfiguration {
    domain: string
}

export interface Configuration {
    brand: BrandConfiguration
    network: NetworkConfiguration
}

export default function load(): Configuration {
	const brand: BrandConfiguration = {
		name: (process.env.BRAND_NAME || "")
	};
	const network: NetworkConfiguration = {
		domain: (process.env.NETWORK_DOMAIN || "")
	};
	return {brand, network};
}