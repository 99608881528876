import React from "react";
import {HashRouter, Route, Routes} from "react-router-dom";

import {AppBarLayout} from "../components/app-bar/component";
import PrivateRoute from "../components/private-route/component";

import {BlankPage, ChatPage, LoginPage, OrderPage} from "../pages/pages";

import {Properties as ApplicationProperties} from "./application";

export interface Properties extends ApplicationProperties {

}

export const ROUTE_ITEMS = {
	home: ["/home", "/"], login: ["/login"], select_store: ["/select-store"],
	order: ["/order", "/orders"],
	chat: ["/chat", "/chats"]
};

export default function Router(props: Properties) {
	return (
		<HashRouter>
			<Routes>
				{ROUTE_ITEMS.home.map(v => <Route path={v} element={<AppBarLayout><BlankPage/></AppBarLayout>}/>)}
				{ROUTE_ITEMS.login.map(v => <Route path={v} element={<AppBarLayout><LoginPage/></AppBarLayout>}/>)}
				{ROUTE_ITEMS.order.map(v => <Route path={v} element={<PrivateRoute element={<OrderPage/>}/>}/>)}
				{ROUTE_ITEMS.chat.map(v => <Route path={v} element={<PrivateRoute element={<ChatPage/>}/>}/>)}
			</Routes>
		</HashRouter>
	);
}