import React, {useCallback, useEffect, useState} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import {
	Container, Switch, Grid, CircularProgress, FormGroup, FormControlLabel,
	Button, IconButton, Alert, Box, Select, Autocomplete, TextField, Chip, TextFieldProps
} from "@mui/material";
import {Notifications as NotificationsIcon, NotificationsActive as NotificationsActiveIcon} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

import vow from "../../../../pkg/utils/vow";
import logger from "../../../../pkg/utils/logger";

import {OrderCollection} from "../../../services/actions/actions";
import {useStore} from "../../contexts/store/store";
import {useAuth} from "../../contexts/auth/auth";

import useInterval from "../../components/hooks/interval";
import {useAudioEffect} from "../../components/hooks/audio";
import useToast from "../../components/hooks/toast";

import {ROUTE_ITEMS} from "../../application/router";

import OrderTable from "./order-table/order-table";
import OrderDialog from "./order-dialog/order-dialog";

import notification_sound from "../../../../config/const/assets/audio/notification.mp3";

export interface Properties {

}

const BACKEND_PAGINATION_COUNT = 250;
const DEFAULT_PAGINATION_STATUS = ["approval",  "progress"];

const TABLE_PAGINATION_ORDER_COUNT = [10, 25, 50];
const REFRESH_INTERVAL = 15000;

export default function OrderPage(props: Properties) {
	const {t, i18n} = useTranslation();
	const navigate = useNavigate();

	const [playing_effect, playEffect] = useAudioEffect(notification_sound);

	const [orders, setOrders] = useState<OrderCollection.OrderResult[]>([]);
	const [selected_order, setSelectedOrder] = useState<OrderCollection.OrderResult>();

	const [order_ids, setOrderIds] = useState<string[]>(DEFAULT_PAGINATION_STATUS);

	const [is_auto_refreshing, setIsAutoRefreshing] = useState<boolean>(true);
	const [refreshing_interval, setRefreshingInterval] = useState<ReturnType<typeof setTimeout>>();

	const [pagination_page, setPaginationPage] = useState<number>(0);
	const [pagination_count, setPaginationCount] = useState<number>(TABLE_PAGINATION_ORDER_COUNT[0]);

	const [status_filter, setStatusFilter] = useState<OrderCollection.OrderStatus[]>([]);

	const [is_loaded, setIsLoaded] = useState<boolean>(false);

	const [Toast, toastify] = useToast();

	const auth_context = useAuth();
	if (!auth_context) throw new Error("Context must be called inside provider");

	const store_context = useStore();
	if (!store_context) throw new Error("Context must be called inside provider");

	const selected_store = store_context.getSelectedStore();
	if (selected_store == null) {
		return (<Navigate to={ROUTE_ITEMS.home[0]}/>);
	}

	const getOrders = useCallback(async () => {
		let current_page = "";
		const current_orders: OrderCollection.OrderResult[] = [];

		let stack = 0;
		while (stack++ < 200) {
			const result = await vow.handle(OrderCollection.paginate({
				store_id: selected_store.id, checkpoint: current_page,
				status: status_filter
			}));
			if (result instanceof Error) {
				logger.error(`Can't get orders(s): ${result.message}`);
				toastify({message: t("network-error"), severity: "error"});
				return;
			}
			if (!(result && result.items)) {
				logger.error("Can't get orders(s)");
				toastify({message: t("network-error"), severity: "error"});
				return;
			}
			if (!result.items.length) break;

			current_orders.push(...result.items);

			if (!(result.checkpoint && result.checkpoint.length)) {
				break;
			}

			current_page = result.checkpoint;
		}

		setOrders(current_orders);
	}, [status_filter]);

	useInterval(() => {
		refreshInterval();
	}, is_auto_refreshing ? REFRESH_INTERVAL : null);

	useEffect(() => {
		(async () => {
			await getOrders();
			setIsLoaded(true);
		})();
	}, [is_loaded, status_filter]);

	useEffect(() => {
		const current_ids = orders.map(i => i.id).sort();

		if (is_loaded) {
			const previous_ids = order_ids.sort();
			const count_ids = current_ids.length - previous_ids.length;

			if (count_ids >= 0 && !current_ids.every((v, i) => v == previous_ids[i])) {
				playEffect();
				toastify({message: t("new-order-info", {count: count_ids}), severity: "info"});
			}
		}

		setOrderIds(current_ids);
	}, [orders]);

	const refreshInterval = () => {
		getOrders();
	};

	const handleOnRefresh = () => {
		getOrders();
	};

	const handleOnClickBell = () => {
		playEffect();
	};

	const handlePaginationPage = (page: number) => {
		setPaginationPage(page);
	};

	const handlePaginationCount = (count: number) => {
		setPaginationCount(count);
	};

	const handleSelectItem = (id: string) => {
		setSelectedOrder(orders.find(v => v.id === id));
	};

	const handleOrderStatus = (status: OrderCollection.OrderStatus[]) => {
		setStatusFilter(status);
	};

	const handleCloseDialog = () => {
		setSelectedOrder(undefined);
	};

	const handleOnChangeOrder = async (order: OrderCollection.OrderResult) => {
		toastify({message: t("update-order-success"), severity: "success"});
		getOrders();
	};

	const handleOnChangeOrderError = async (error: Error) => {
		logger.error(error.message);
		toastify({message: t("network-error"), severity: "error"});
	};

	if (!is_loaded) return (
		<Container component="main" maxWidth="sm">
			<Grid container direction="column" alignItems="center">
				<CircularProgress/>
			</Grid>
		</Container>
	);

	return (
		<React.Fragment>
			<Toast/>
			{selected_order &&
				<OrderDialog order={selected_order}
					onClose={handleCloseDialog}
					onChange={handleOnChangeOrder}
					onChangeError={handleOnChangeOrderError}
				/>
			}
			<Box
				sx={(theme) => ({
					width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", flexWrap: "wrap",
					padding: theme.spacing(0.5), gap: theme.spacing(0.5)
				})}>
				<Box sx={(theme) => ({display: "flex", flex: 1, flexWrap: "wrap", gap: theme.spacing(1.5), justifySelf: "flex-start"})}>
					<Autocomplete
						sx={{flexBasis: "100%", width: "100%", maxWidth: "300px"}}
						limitTags={1}
						value={status_filter}
						multiple
						options={["approval", "progress", "done"]}
						getOptionLabel={(option) => t(`order-status-${option}`)}
						renderOption={(props, option) => (
							<li {...props}>{t(`order-status-${option}`)}</li>
						)}
						onChange={(e, v) => handleOrderStatus(v as OrderCollection.OrderStatus[])}
						renderInput={(params) => (
							<TextField {...(params as TextFieldProps)} label={t("order-table-status")}/>
						)}
					/>
				</Box>
				<Box
					sx={(theme) => ({display: "flex", justifyContent: "flex-end", flexWrap: "wrap", gap: theme.spacing(1.5),
						marginLeft: "auto"
					})}>
					<Button sx={{height: "auto"}} variant="contained" color="primary" onClick={() => handleOnRefresh()}>
						{t("order-refresh")}
					</Button>
					<FormGroup
						sx={(theme) => ({
							border: `1px solid ${theme.palette.primary.main}`, paddingLeft: theme.spacing(0.5),
							paddingRight: theme.spacing(1), borderRadius: "5px"
						})}>
						<FormControlLabel
							control={<Switch checked={is_auto_refreshing} onChange={(e) => setIsAutoRefreshing(e.currentTarget.checked)} name="auto-refreshing"/>}
							label={t("order-auto-refreshing")} labelPlacement="start"
						/>
					</FormGroup>
					<Button sx={{borderRadius: "25px"}} variant="contained" onClick={handleOnClickBell}>
						{playing_effect ? <NotificationsActiveIcon/> : <NotificationsIcon/>}
					</Button>
				</Box>
			</Box>

			<OrderTable
				orders={orders}
				pagination={{
					page: pagination_page, count: pagination_count,
					options: TABLE_PAGINATION_ORDER_COUNT
				}}
				onChangePaginationPage={handlePaginationPage}
				onChangePaginationCount={handlePaginationCount}
				onSelectItem={handleSelectItem}
			/>
		</React.Fragment>
	);
}